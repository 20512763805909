<template>
  <div>
    <b-row class="mt-4">
      <b-col cols="6">
        <KTCard>
          <!--页面标题-->
          <template v-slot:title>
            <h3 class="card-title">
              <b-icon icon="blockquote-right" class="mx-3"></b-icon>
              签名生成工具
            </h3>
          </template>

          <!--内容区域-->
          <template v-slot:body>
            <div style="min-height: 70vh">
              <form class="form" id="sign_form" v-on:submit.prevent>
                <b-row class="mt-0">
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">待调试接口:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-select name="type" type="text" v-model="sign.request.type" placeholder="待调试接口...">
                        <option :value="null">请选需要生成签名的接口...</option>
                        <option :value="1">下单交易请求(/trade/preorder)</option>
                        <option :value="3">订单查询请求(/trade/order/query)</option>
                        <option :value="5">提现申请请求(/trade/withdraw)</option>
                        <option :value="7">提现查询请求(/trade/withdraw/query)</option>
                        <option :value="9">余额查询请求(/trade/balance/query)</option>
                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">请求参数(JSON):</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-textarea rows="7" type="text" name="json" v-model="sign.request.json"
                        placeholder="将请求参数转换成JSON后放在此处...">
                      </b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">客户端私钥:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-textarea rows="5" name="secretKey" type="text" v-model="sign.request.secretKey"
                        placeholder="请将商户个人中心获取到的客户端私钥填入此处...">
                      </b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="d-flex justify-content-between border-bottom pb-6">
                  <b-col cols="3" class="text-left">

                  </b-col>
                  <b-col cols="9">
                    <div>
                      <button v-on:click="generateSign" class="
                      btn btn-success
                      font-weight-bold
                      text-uppercase
                      px-5
                      py-2
                    ">
                        <v-icon>flaticon2-gear</v-icon>
                        生成签名
                      </button>
                    </div>
                  </b-col>
                </b-row>


                <b-row class="mt-8">
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">参与签名字符串:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-textarea rows="5" type="text" v-model="sign.response.signStr" placeholder="参与签名字符串...">
                      </b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-0">
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">签名:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-textarea rows="5" type="text" v-model="sign.response.sign" placeholder="签名...">
                      </b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </form>
            </div>
          </template>
        </KTCard>

      </b-col>
      <b-col cols="6">
        <KTCard>
          <!--页面标题-->
          <template v-slot:title>
            <h3 class="card-title">
              <b-icon icon="blockquote-right" class="mx-3"></b-icon>
              签名校验工具
            </h3>
          </template>

          <!--内容区域-->
          <template v-slot:body>
            <div style="min-height: 70vh">
              <form class="form" id="check_sign_form" v-on:submit.prevent>
                <b-row class="mt-0">
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">待调试接口:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-select name="type" type="text" v-model="checkSign.request.type" placeholder="待调试接口...">
                        <option :value="null">请选需要生成签名的接口...</option>
                        <option :value="2">下单交易响应结果(/trade/preorder)</option>
                        <option :value="4">订单查询响应结果(/trade/order/query)</option>
                        <option :value="6">提现申请响应结果(/trade/withdraw)</option>
                        <option :value="8">提现查询响应结果(/trade/withdraw/query)</option>
                        <option :value="10">余额查询响应结果(/trade/balance/query)</option>
                        <option :value="11">订单回调</option>
                        <option :value="12">提现回调</option>

                      </b-form-select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">响应结果(JSON):</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-textarea rows="7" type="text" name="json" v-model="checkSign.request.json"
                        placeholder="将服务端响应的结果转换成JSON后放在此处...">
                      </b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">服务端公钥:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-textarea rows="5" name="secretKey" type="text" v-model="checkSign.request.secretKey"
                        placeholder="请将商户个人中心获取到的服务端公钥填入此处...">
                      </b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="d-flex justify-content-between border-bottom pb-6">
                  <b-col cols="3" class="text-left">

                  </b-col>
                  <b-col cols="9">
                    <div>
                      <button v-on:click="check" class="
                      btn btn-warning
                      font-weight-bold
                      text-uppercase
                      px-5
                      py-2
                    " data-wizard-type="action-submit">
                        <v-icon>flaticon2-shrink</v-icon>
                        校验签名
                      </button>
                    </div>
                  </b-col>
                </b-row>


                <b-row class="mt-8">
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">参与签名字符串:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-textarea rows="5" type="text" v-model="checkSign.response.signStr"
                        placeholder="参与签名字符串...">
                      </b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mt-0">
                  <b-col cols="3" class="text-left">
                    <label class="form-group-label">校验结果:</label>
                  </b-col>
                  <b-col cols="9">
                    <b-form-group>
                      <b-form-textarea rows="5" type="text" v-model="checkSign.response.result" placeholder="校验结果...">
                      </b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>


              </form>
            </div>
          </template>
        </KTCard>

      </b-col>
    </b-row>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import KTCard from "@/view/content/Card.vue";
import FileUpload from "@/view/pages/oms_pages/plugin/FileUpload";
import ApiService from "@/core/services/api.service";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import Swal from "sweetalert2";

export default {
  name: "Tools",
  components: {
    KTCard
  },
  data() {
    return {
      sign: {
        request: {
          type: null
        },
        response: {

        }
      },
      checkSign: {
        request: {
          type: null

        },
        response: {

        }
      }
    };
  },

  created() {

  },

  mounted() {
    // Create a FormValidation instance
    let signForm = KTUtil.getById("sign_form");
    this.fv = formValidation(signForm, {
      fields: {
        type: {
          validators: {
            notEmpty: {
              message: "请选择需调试的接口!",
            },
          },
        },
        json: {
          validators: {
            notEmpty: {
              message: "请求参数不能为空!",
            },
          },
        },
        secretKey: {
          validators: {
            notEmpty: {
              message: "客户端私钥不能为空!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
      },
    });

    let checkSignForm = KTUtil.getById("check_sign_form");
    this.fv1 = formValidation(checkSignForm, {
      fields: {
        type: {
          validators: {
            notEmpty: {
              message: "请选择需调试的接口!",
            },
          },
        },
        json: {
          validators: {
            notEmpty: {
              message: "响应结果不能为空!",
            },
          },
        },
        secretKey: {
          validators: {
            notEmpty: {
              message: "服务端公钥不能为空!",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
      },
    });
  },

  methods: {
    generateSign() {
      let that=this;
      this.fv.validate().then(function (status) {
        if (status === "Valid") {
          ApiService.post("/api/tools/sign", that.sign.request).then(({
            data
          }) => {
            if (data.success) {
              that.$set(that.sign.response, "signStr", data.result.signStr);
              that.$set(that.sign.response, "sign", data.result.sign);
            } else {
              Swal.fire({
                title: "生成失败！",
                text: data.message,
                icon: "warning",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000,
              });
            }
          });
        }
      });
    },
    check() {
      let that=this;
      this.fv1.validate().then(function (status) {
        if (status === "Valid") {
          ApiService.post("/api/tools/check/sign", that.checkSign.request).then(({
            data
          }) => {
            if (data.success) {
              that.$set(that.checkSign.response, "signStr", data.result.signStr);
              that.$set(that.checkSign.response, "result", data.result.result);
            } else {
              Swal.fire({
                title: "校验失败！",
                text: data.message,
                icon: "warning",
                confirmButtonClass: "btn btn-success",
                heightAuto: false,
                timer: 3000,
              });
            }
          });
        }
      });
    }

  },
  computed: {

  },

  watch: {

  },
};
</script>